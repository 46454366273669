.outer {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.grid {
    display: flex;
    flex-direction: row;
    flex-basis: 35%;
    background-color: black;
    overflow-x: hidden;
}

.grid img {
    height: 100%;
    margin-right: 1px;
    margin-left: 2px;
}

.appBarOuter {
    display: flex;
    flex-direction: column;
    flex-basis: 30%;
}

.nav {
    display: flex;
    flex-direction: row;
    flex-basis: 20%;
    justify-content: center;
    align-items: center;
}

.links {
    font-family: 'Gloria Hallelujah', cursive;
    text-decoration: none;
    font-size: 2vh;
    color: black;
    margin: 0 5vw 0 5vw;
}

.nav a {
    font-family: 'Gloria Hallelujah', cursive;
    text-decoration: none;
    font-size: 2vh;
    color: black;
    margin: 0 5vw 0 5vw;
}

.appBar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 5vw;
    padding-right: 5vw;
    flex-basis: 60%;
}

.inner {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.break {
    display: none;
    height: 0px;
}

.name {
    font-family: 'Rock Salt', cursive;
    font-size: 4vh;
}

.profession {
    font-family: 'Gloria Hallelujah', cursive;
    font-size: 2.8vh;
    padding-left: 1em;
}

.icons {
    display: flex;
    flex-direction: row;
}

.icons img {
    padding-right: 20px;
}

#contact {
    background-color: transparent;
    border: 2px solid black;
    padding-left: 2em;
    padding-right: 2em;
}

#contact:hover {
    background-color: black;
}

#contact:hover>a {
    color: white;
}

#contact a {
    text-decoration: none;
    color: black;
    font-family: 'Gloria Hallelujah', cursive;
}

sup {
    font-size: 1vh;
}

@media screen and (max-width: 700px) {
    .grid {
        flex-basis: 50%;
        background-color: #9ea7a6;
        justify-content: center;
    }

    .grid>img:not(:first-child) {
        display: none;
    }

    .appBarOuter {
        flex-basis: 50%;
    }

    .appBar {
        flex-basis: 100%;
        flex-direction: column;
        justify-content: space-evenly;
    }

    .lower {
        display: none;
    }

    .inner {
        justify-content: center;
    }

    .break {
        display: block;
    }

    .profession {
        padding-left: 0px;
    }

    .nav {
        display: none;
    }
}